<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CTableWrapper
          :items="data"
          :fields="fields"
          hover
          :striped="true"
          :border="true"
          small
          fixed
          caption="Lista de clientes"
          icon="fas fa-users-viewfinder"
          btn_name="Cliente"
          @show_modal="mtd_open_modal"
          @action_edit="mtd_show"
          @action_delete="mtd_delete"
          :size="'md'"
          :button_new="true"
          :actions="true"
          :withActions="'6%'"
          :buttonEdit="true"
          :buttonDelete="true"
        />
      </CCol>
    </CRow>
    <!-- modal -->

    <CModalForm
      :size="'lg'"
      :title="modal.title"
      :button="cp_button"
      :show.sync="modal.modal_form"
      @mtd_action="mtd_action"
    >
      <CRow>
        <CCol sm="3">
          <CInput
            label="Documento"
            placeholder="Digite documento"
            v-model="client.document"
            maxlength="8"
            v-on:keypress="isNumber($event)"
            v-on:keyup.enter="mtd_search_document"
          />
        </CCol>
        <CCol sm="9">
          <CInput
            label="Nombre"
            placeholder="Digite nombre"
            v-model="client.name"
            :disabled="disabled"
          />
        </CCol>
        <CCol sm="3">
          <CInput
            label="Telefóno"
            placeholder="Digite teléfono"
            v-model="client.celphone"
          />
        </CCol>
        <CCol sm="3">
          <CInput
            label="Celular"
            placeholder="Digite Celular"
            v-model="client.phone"
          />
        </CCol>
        <CCol sm="3">
          <CTextarea
            label="Dirección"
            placeholder="Digite Dirección"
            v-model="client.address"
          />
        </CCol>
        <CCol sm="3" v-if="modal.action != 'store'">
          <SelectDev
            label="Estado"
            :options="status"
            placeholder="Estado"
            :value.sync="client.status"
          >
          </SelectDev>
        </CCol>
      </CRow>
    </CModalForm>

    <!-- modal delete -->
    <cModalDelete
      title="Borrar Cliente"
      :boo_modal="modal_delete.boo"
      :item="modal_delete.item"
      @close_delete="close_delete"
      @mtd_commit="mtd_commit"
    ></cModalDelete>
  </div>
</template>

<script>
const fields = [
  { key: "Id", label: "Id", _style: "width:3%" },
  { key: "document", label: "Documento", _style: "width:20%;" },
  { key: "name", label: "Nombre", _style: "width:20%;" },
  { key: "status", label: "Estado", _style: "width:5%;" },
];
import CTableWrapper from "../../components/shared/datatable/Table.vue";
import CModalForm from "../../components/shared/modals/cModalForm.vue";
import cModalDelete from "../../components/shared/modals/cModalDelete.vue";
import SelectDev from "../../components/shared/inputs/cSelect.vue";

import { mapActions } from "vuex";
import { bus } from "../../main";
export default {
    name: "v-mantenance-client",
  components: { CTableWrapper, CModalForm, cModalDelete, SelectDev },
  data() {
    return {
      prefix: "client",
      fields,
      data: [],
      status: [
        { label: "Activo", value: 1 },
        { label: "Inactivo", value: 0 },
      ],
      disabled:true,
      client: {
        id: "",
        document:"",
        name: "",
        address:"",
        phone:"",
        celphone:"",
        status: [],
      },
      modal: {
        action: "",
        title: "",
        modal_form: false,
      },
      modal_delete: {
        boo: false,
        item: [],
      },
    };
  },
  computed: {
    cp_button() {
      if (this.client.document.length > 0 && this.client.name.length > 0) return false;
      return true;
    },
  },
  created() {
    this.mtd_getdata();
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtd_getdata: function () {
      this.get({
        url: this.$store.getters.get__url + "/" + this.prefix,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.data = response.data;
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    mtd_open_modal: function (boo, action) {
      this.modal.modal_form = boo;
      this.modal.action = action;
      action == "store"
        ? (this.modal.title = "Nuevo Cliente")
        : (this.modal.title = "Editar Cliente");
      action == "store"
        ? (this.client = {
            id: "",
            document:"",
            name: "",
            address:"",
            phone:"",
            celphone:"",
            status: [],
          })
        : (this.client = this.client);
    },
    mtd_search_document: function(){
      if (this.client.document.length == 8) {
        this.get({
          url: this.$store.getters.get__url + "/consultar/"+this.client.document,
          token: this.$store.getters.get__token,
        })
        .then((response) => {
          console.log(response);
          if (response.state == 1) {
            let resp  = response.boo == 1? 'Encontrado en la base de datos!!': (response.boo == 2? 'Encontrado por la Api': (response.boo == 3 ?'Ocurrió un error interno': 'Error las consultar' ));
            let color = response.boo == 1? 'success': (response.boo == 2? 'success': (response.boo == 3 ?'warning': 'warning' ));
            this.client.name = response.name;
            bus.$emit("alert", {
              color: color,
              message: resp,
            });
          }else{
            bus.$emit("alert", {
              color: 'danger',
              message: response.error,
            });
          }
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: 'danger',
            message: errors,
          });
        });
      }else{
        bus.$emit("alert", {
          color: 'danger',
          message: 'EL documento debe tener 8 digitos!',
        });
      }
    },
    mtd_action: function () {
      this.post({
        url:
          this.$store.getters.get__url +
          "/" +
          this.prefix +
          "/" +
          this.modal.action,
        token: this.$store.getters.get__token,
        params: this.client,
      })
        .then((response) => {
          let color = "success";
          let message = "";
          if (this.modal.action == "store") {
            message = "REGISTRADO CORRECTAMENTE";
          } else {
            message = "EDITADO CORRECTAMENTE";
          }
          this.mtd_getdata();
          this.modal = {
            action: "",
            title: "",
            modal_form: false,
            name: "",
          };
          bus.$emit("alert", {
            color: color,
            message: message,
          });
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
    mtd_show: function (id) {
      this.get({
        url:
          this.$store.getters.get__url + "/" + this.prefix + "/" + id + "/show",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.client.id = response.data[0].Id;
          this.client.name = response.data[0].name;
          this.client.status = response.data[0].status == "Activo" ? 1 : 0;
          this.mtd_open_modal(true, "update");
        })
        .catch((errors) => {});
    },
    mtd_delete: function (item) {
      this.modal_delete.boo = true;
      this.modal_delete.item = item;
    },
    close_delete: function () {
      this.modal_delete.boo = false;
    },
    mtd_commit: function () {
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix + "/destroy",
        token: this.$store.getters.get__token,
        params: {
          id: this.modal_delete.item.Id,
        },
      })
        .then((response) => {
          let temp = [];
          this.data.forEach((element) => {
            if (element.Id != response.data.id) {
              temp.push(element);
            }
          });
          this.data = temp;
          this.modal_delete = {
            boo: false,
            item: [],
          };
          bus.$emit("alert", {
            color: "success",
            message: "ELIMINADO CORRECTAMENTE",
          });
        })
        .catch((errors) => {});
    },
    /**helpers */
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>